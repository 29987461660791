import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userRole: "",
    userEmail: "",
    currentUser: null,
    stockData: [],
    unWantedUsers: [],
    carsList: [],
    carData: {
      regNumber: "",
      carStock: [],
    },
  },
  mutations: {
    setUserRole(state, result) {
      state.userRole = result;
    },
    setUserEmail(state, result) {
      state.userEmail = result;
    },
    setCurrentUser(state, result) {
      state.currentUser = result;
    },
    setStockData(state, result) {
      state.stockData = result;
    },
    addNewProductToStock(state, product) {
      state.stockData.push(product);
    },
    setCarData(state, result) {
      state.carData = result;
    },
    setCarStock(state, result) {
      state.carData.carStock = result;
    },
    addNewProductToCar(state, product) {
      state.carData.carStock.push(product);
    },
    addNewCarToList(state, car) {
      state.carsList.push(car);
    },
    setCarsList(state, list) {
      state.carsList = list;
    },
    setUnWantedUsers(state, list) {
      state.unWantedUsers = list;
    },
    addNewUnWantedUser(state, email) {
      state.unWantedUsers.push(email);
    },
  },
  actions: {},
  modules: {},
});
