<template>
  <div class="main-div">
    <section>
      <b-tabs type="is-boxed">
        <b-tab-item label="Skapa ett konto" icon="user-plus">
          <b-field label="Email" :label-position="labelPosition">
            <b-input
              placeholder="tex : barac@obama.com"
              v-model="enteredEmail"
              icon-pack="fas"
              type="email"
              required
              icon="at"
            ></b-input>
          </b-field>
          <b-field label="Lösenord" :label-position="labelPosition">
            <b-input
              placeholder="tex : topsecretpassword"
              v-model="enteredPassword"
              type="password"
              password-reveal
              required
              icon-pack="fas"
              icon="key"
            ></b-input>
          </b-field>
          <b-field label="Roll" :label-position="labelPosition">
            <b-select placeholder="Välja rollen" required icon="user-tag" v-model="enteredRole">
              <option value="tech">Tekniker</option>
              <option value="admin">Admin</option>
            </b-select>
          </b-field>
          <b-button type="is-success" @click="createUser()">SKAPA</b-button>
        </b-tab-item>

        <b-tab-item label="Ta bort ett konto" icon="user-minus">
          <b-field
            label="Ange adressen till kontot som ni ville tabort"
            :label-position="labelPosition"
          >
            <b-input
              placeholder="tex : barac@obama.com"
              v-model="enteredEmail"
              icon-pack="fas"
              type="email"
              required
              icon="at"
            ></b-input>
          </b-field>
          <b-button label="TABORT" type="is-danger" size="is-medium" @click="confirmCustomDelete" />
        </b-tab-item>
        <!--
        <b-tab-item label="Ändra på ett konto" icon="user-edit">
          <b-field
            label="Ange adressen till kontot som ni ville tabort"
            :label-position="labelPosition"
          >
            <b-input
              placeholder="tex : barac@obama.com"
              v-model="enteredEmail"
              icon-pack="fas"
              type="email"
              required
              icon="at"
            ></b-input>
          </b-field>
          <b-field label="Den nya rollen" :label-position="labelPosition">
            <b-select placeholder="Välja rollen" required icon="user-tag" v-model="enteredRole">
              <option value="tech">Tekniker</option>
              <option value="admin">Admin</option>
            </b-select>
          </b-field>
          <b-button type="is-warning" @click="createUser()">ÄNDRA</b-button>
        </b-tab-item>
        -->
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { db, auth } from "../Firebase";
//import { db } from "../Firebase";
export default {
  name: "UserMangement",
  data() {
    return {
      labelPosition: "on-border",
      enteredEmail: "",
      enteredPassword: "",
      enteredRole: ""
    };
  },

  methods: {
    createUser() {
      if (this.enteredRole != "") {
        auth
          .createUserWithEmailAndPassword(
            this.enteredEmail,
            this.enteredPassword
          )
          .then(user => {
            this.$store.commit("setCurrentUser", user.user);
            this.userUpdateRole();
            this.danger("Kontot har skapats", "is-success");
          })
          .catch(err => {
            this.danger(err.message, "is-danger");
            console.log(err);
            console.log(auth.currentUser.displayName);
          });
      } else {
        this.danger("Rollen bör väljas", "is-danger");
      }
    },
    userUpdateRole() {
      var user = auth.currentUser;

      user
        .updateProfile({
          displayName: this.enteredRole
          //photoURL: "https://example.com/jane-q-user/profile.jpg"
        })
        .then(function() {
          // Update successful.
          //console.log(auth.currentUser.displayName);
        })
        .catch(function(error) {
          // An error happened.
          console.log(error);
        });
    },
    danger(msg, status) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,

        position: "is-bottom",
        type: status
      });
    },
    confirmCustomDelete() {
      this.$buefy.dialog.confirm({
        title: "TABORT KONTOT",
        message:
          "ÄR DU SÄKERT ATT <b>TABORT</b> DETTA KONTOT ? TÄNK PÅ ATT DET OÅNGERBART",
        confirmText: "SÄKERT",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          //this.$store.commit("setUnWantedUsers", []);
          var check = this.$store.state.unWantedUsers.includes(
            this.enteredEmail
          );
          if (check) {
            this.danger("Detta kontot avktiverats redan !!! ", "is-danger");
          } else {
            this.$store.commit("addNewUnWantedUser", this.enteredEmail);
            this.danger("KONTOT HAR TAGITS BORT", "is-danger");
            this.enteredEmail = "";
            this.updateUnWantedDB();
          }
          console.log(this.$store.state.unWantedUsers);
          /*
          var unWantedList = db.collection("Gothenburg").doc("unWantedUsers");
          unWantedList.update({
            users: db.firebase.firestore.FieldValue.arrayUnion(
              this.enteredEmail
            )
          });
          */
        }

        //this.$buefy.toast.open("Account deleted!")
      });
    },
    updateUnWantedDB() {
      var ref = db.collection("Gothenburg").doc("unWantedUsers");
      ref.update({
        users: this.$store.state.unWantedUsers
      });
    }
  }
};
</script>

<style scoped>
.main-div {
}
</style>