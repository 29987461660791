<template>
  <div>
    <a class="logout-tab" @click="logout()">LOGGAUT</a>

    <b-collapse aria-id="contentIdForA11y2" class="stockMng" animation="slide" v-model="stockMng">
      <template #trigger>
        <div class="stock-heading" role="button" aria-controls="contentIdForA11y2">
          <strong>PRODUKTERÖVERSIKT</strong>
        </div>
      </template>
      <div>
        <StockMangement />
      </div>
    </b-collapse>
    <div class="stock-foot"></div>

    <b-collapse aria-id="contentIdForA11y2" class="userMng" animation="slide" v-model="userMng">
      <template #trigger>
        <div class="user-heading" role="button" aria-controls="contentIdForA11y2">
          <strong>ANVÄNDARHANTERING</strong>
        </div>
      </template>
      <div class="panel-block">
        <UserMangement />
      </div>
    </b-collapse>
    <div class="user-foot"></div>
    <b-collapse aria-id="contentIdForA11y2" class="carMng" animation="slide" v-model="carMng">
      <template #trigger>
        <div class="car-heading" role="button" aria-controls="contentIdForA11y2">
          <strong>BILHANTERING</strong>
        </div>
      </template>
      <!--
      <p class="panel-tabs">
        <a class="is-active">All</a>
        <a>Public</a>
        <a>Private</a>
      </p>
      -->
      <div class="panel-block">
        <CarMangement />
      </div>
    </b-collapse>
    <div class="car-foot"></div>
    <b-collapse aria-id="contentIdForA11y2" class="userMng" animation="slide" v-model="productMng">
      <template #trigger>
        <div class="product-heading" role="button" aria-controls="contentIdForA11y2">
          <strong>PRODUKTERHANTERING</strong>
        </div>
      </template>
      <div class="panel-block">
        <ProductsMangement />
      </div>
    </b-collapse>
    <div class="pro-foot"></div>
    <div class="foot"></div>
  </div>
</template>

<script>
import UserMangement from "./UserMangement";
import CarMangement from "./CarMangement";
import ProductsMangement from "./ProductsMangement";
import StockMangement from "./StockMangement";
//import { DialogProgrammatic as Dialog } from "buefy";
export default {
  name: "AdminPage",
  data() {
    return {
      userMng: false,
      carMng: false,
      productMng: false,
      stockMng: false
    };
  },
  components: {
    UserMangement,
    CarMangement,
    ProductsMangement,
    StockMangement
  },
  methods: {
    logout() {
      this.$buefy.dialog.confirm({
        message: "ÄR DU SÄKERT ATT DU VILLE LOGGAUT ?",
        onConfirm: () => {
          this.$store.commit("setUserRole", "");
        }
      });
    }
  }
};
</script>

<style scoped>
.user-heading {
  background-color: coral;
  border-radius: 16px 16px 0px 0px;
  font-size: 25px;
  padding: 15px;
}
.stock-heading {
  background-color: rgb(174, 4, 174);
  border-radius: 16px 16px 0px 0px;
  font-size: 25px;
  padding: 15px;
}
.userMng {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.stockMng {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.logout-tab {
  align-items: flex-end;
  align-content: flex-end;
  flex-direction: row;
}
.carMng {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.car-heading {
  background-color: cornflowerblue;
  border-radius: 16px 16px 0px 0px;
  font-size: 25px;
  padding: 15px;
}
.product-heading {
  background-color: rgb(8, 159, 38);
  border-radius: 16px 16px 0px 0px;
  font-size: 25px;
  padding: 15px;
}
.pro-foot {
  background-color: rgb(8, 159, 38);
  height: 10px;
  border-radius: 0px 0px 16px 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10;
}
.user-foot {
  background-color: coral;
  height: 10px;
  border-radius: 0px 0px 16px 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10;
}

.stock-foot {
  background-color: rgb(174, 4, 174);
  height: 10px;
  border-radius: 0px 0px 16px 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10;
}
.car-foot {
  background-color: cornflowerblue;
  height: 10px;
  border-radius: 0px 0px 16px 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10;
}
.foot {
  height: 40px;
}
</style>