<template>
  <div class="home">
    <img
      alt="logo"
      class="logo"
      src="https://rouxhealthcare.com/wp-content/uploads/2020/09/Roux_healthcare_logo.svg"
    />

    <div v-if="this.$store.state.userRole === ''">
      <Login />
    </div>
    <div v-else-if="this.$store.state.userRole === 'Admin'">
      <AdminPage />
    </div>
    <div v-else>
      <Welcome />
    </div>

    <!-- <HelloWorld msg="Welcome to Your Vue.js App" />-->
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from "@/components/Welcome.vue";
import AdminPage from "@/components/AdminPage";
import Login from "@/components/Login";

export default {
  name: "Home",
  components: {
    Welcome,
    AdminPage,
    Login
  }
};
</script>

<style scoped>
.logo {
  width: 270px;
  height: 270px;
  animation: bounceIn 1.5s;
  transform: rotate(0deg) scale(1) translateZ(0);
  transition: all 0.4s cubic-bezier(0.8, 1.8, 0.75, 0.75);
  cursor: pointer;
}
.logo:hover {
  transform: rotate(360deg) scale(1.1);
}
@keyframes bounceIn {
  0% {
    opacity: 1;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(2);
  }

  70% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
