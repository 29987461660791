<template>
  <div>
    <a class="logout-tab" @click="logout()">LOGGAUT</a>
    <div class="bil-table">
      MIN BIL PRODUKTER
      <b-table
        :data="data"
        ref="table"
        paginated
        bordered
        striped
        hoverable
        scrollable
        per-page="10"
        detailed
        detail-key="articleNumber"
        show-detail-icon
      >
        <b-table-column
          centered
          field="articleNumber"
          label=" Artikelnummer"
          numeric
          v-slot="data"
        >{{data.row.articleNumber}}</b-table-column>

        <b-table-column
          centered
          field="articleName"
          label="Artikelbenämning"
          v-slot="data"
          searchable
        >{{data.row.articleName}}</b-table-column>
        
        <b-table-column centered field="currentQuaCar" label=" Antal i bilen" numeric v-slot="data">
          <div v-if="data.row.currentQuaCar < data.row.minCar">
            <div class="dangerBackground">{{data.row.currentQuaCar}}</div>
          </div>
          <div v-else>{{data.row.currentQuaCar}}</div>
        </b-table-column>
        <b-table-column
          centered
          field="minCar"
          label="Minst i bilen"
          numeric
          v-slot="data"
        >{{data.row.minCar}}</b-table-column>
        
        <template #detail="data">
          <article class="media">
            <figure class="media-left">
              <b-field label="Ange kvantitet" label-position="on-border">
                <b-input
                  placeholder="tex : 12"
                  v-model.number="quantity"
                  type="number"
                  icon-pack="fas"
                  icon="dolly"
                ></b-input>
                <br />
              </b-field>
              <b-button
                class="btn"
                type="is-success"
                @click="shippedToTheClient(data.row)"
              >LÄMNA TILL KUNDEN</b-button>
              <b-button
                class="btn"
                type="is-primary"
                @click="changeQuantity(data.row)"
              >ÖKA KVANTITET I BILEN</b-button>
              <b-field label="FLYTTA TILL ..." label-position="on-border" class="move">
                <b-select placeholder="VÄLJA NÅN BIL ELLER LAGRET" v-model="selectedDis">
                  <option v-for="car in carList" :value="car.reg" :key="car.id">{{car.reg}}</option>
                </b-select>
                <b-button class="btn" type="is-danger" @click="moveThisProductTo(data.row)">FLYTTA</b-button>
              </b-field>
              <!--
              <b-field label="Simple">
                <b-select placeholder="Select a name">
                  <option
                    v-for="option in data"
                    :value="option.id"
                    :key="option.id"
                  >{{ option.user.first_name }}</option>
                </b-select>
              </b-field>
              -->
              <!--
              <b-dropdown aria-role="menu">
                <template #trigger="{ active }">
                  <b-button
                    label="Click me!"
                    type="is-danger"
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                  />
                </template>

                <b-dropdown-item aria-role="menuitem">Action</b-dropdown-item>
                <b-dropdown-item aria-role="menuitem">Another action</b-dropdown-item>
                <b-dropdown-item aria-role="menuitem">Something else</b-dropdown-item>
              </b-dropdown>
              -->
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Enhet : {{ data.row.unit }}</strong>
                  <br />

                  <strong>Inköpspris : {{ data.row.purchasePrice }}</strong>
                  <br />
                  <strong>Leverantör : {{ data.row.supplier }}</strong>
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
    <div class="stock-table">
      LAGRET PRODUKTER
      <b-table
        :data="stockData"
        ref="table"
        paginated
        bordered
        striped
        hoverable
        scrollable
        per-page="10"
        detailed
        detail-key="articleNumber"
        show-detail-icon
      >
        <b-table-column
          centered
          field="articleNumber"
          label=" Artikelnummer"
          numeric
          v-slot="stockData"
        >{{stockData.row.articleNumber}}</b-table-column>

        <b-table-column
          centered
          field="articleName"
          label="Artikelbenämning"
          v-slot="stockData"
          searchable
        >{{stockData.row.articleName}}</b-table-column>
        <b-table-column
          centered
          field="currentQuantity"
          label="Antal i lagret"
          numeric
          v-slot="stockData"
        >
          <div v-if="stockData.row.currentQuantity <= stockData.row.minStock">
            <div class="dangerBackground">{{stockData.row.currentQuantity}}</div>
          </div>
          <div v-else>{{stockData.row.currentQuantity}}</div>
        </b-table-column>
        <b-table-column
          centered
          field="minCar"
          label="Minst i bilen"
          numeric
          v-slot="stockData"
        >{{stockData.row.minCar}}</b-table-column>
        <!--
        <b-table-column
          field="minStock"
          label="Minst i lagret"
          numeric
          v-slot="stockData"
        >{{stockData.row.minStock}}</b-table-column>
        -->
        <template #detail="stockData">
          <article class="media">
            <figure class="media-left">
              <b-field label="Ange kvantitet" label-position="on-border">
                <b-input
                  placeholder="tex : 12"
                  v-model.number="quantity"
                  type="number"
                  icon-pack="fas"
                  icon="dolly"
                ></b-input>
                <br />
              </b-field>

              <b-button
                class="btn"
                type="is-primary"
                @click="moveProductToMyCar(stockData.row)"
              >LÄGG PRODUKTEN TILL BILEN</b-button>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  <strong>Enhet : {{ stockData.row.unit }}</strong>
                  <br />

                  <strong>Inköpspris : {{ stockData.row.purchasePrice }}</strong>
                  <br />
                  <strong>Leverantör : {{ stockData.row.supplier }}</strong>
                </p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { db } from "../Firebase";
export default {
  name: "MyCar",
  props: {},

  data() {
    return {
      defaultOpenedDetails: [],
      isBordered: true,
      quantity: Number,
      active: false,
      selectedDis: String,
      carList: [{ id: 0, reg: "LAGRET" }]
    };
  },
  // mounted() {},

  computed: {
    stockData() {
      return this.$store.state.stockData;
    },
    data() {
      return this.$store.state.carData.carStock;
    }
    /*
    carList() {
      return this.$store.state.carsList;
    }
    */
  },
  methods: {
    fetch() {
      db.collection("Gothenburg")
        .doc("Products")
        .get()
        .then(doc => {
          doc.data().stock.forEach(element => {
            this.$store.commit("addNewProductToStock", element);
          });
        });
    },
    logout() {
      this.$buefy.dialog.confirm({
        message: "ÄR DU SÄKERT ATT DU VILLE LOGGAUT ?",
        onConfirm: () => {
          this.$store.commit("setUserRole", "");
        }
      });
    },
    carsInfo() {
      var id = 1;
      //var myCar = this.$store.state.carData.regNumber
      //var tempArr = [];
      var newCar = {};

      var docRef = db.collection("Gothenburg");
      docRef
        .where("driverEmail", "!=", this.$store.state.userEmail)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var reg = doc.id;
            //console.log(reg);
            //this.$store.commit("setCarData", doc.data());
            //this.danger("Välkommen tillbaka", "is-success");
            id++;
            newCar = { id, reg };
            this.carList.push(newCar);
          });
        })
        .catch(error => {
          console.log(error);
          this.danger("Något har gått fel", "is-danger");
        });
    },
    moveProductToMyCar(row) {
      var result = row.currentQuantity - this.quantity;
      var isExist = this.$store.state.carData.carStock.some(
        product => product.articleNumber == row.articleNumber
      );
      this.editStock(row, result);

      if (this.quantity >= 0) {
        if (isExist) {
          this.$store.state.carData.carStock.filter(product => {
            if (product.articleNumber == row.articleNumber) {
              product.currentQuaCar = product.currentQuaCar + this.quantity;
            }
          });
          this.updateCarDB();

          this.danger("Produkten har flyttats", "is-success");
        } else {
          var p = {
            supplier: row.supplier,
            articleNumber: row.articleNumber,
            articleName: row.articleName,
            unit: row.unit,
            currentQuantity: result,
            purchasePrice: row.purchasePrice,
            totalSum: row.totalSum,
            minStock: row.minStock,
            minCar: row.minCar,
            currentQuaCar: this.quantity
          };
          this.$store.commit("addNewProductToCar", p);
          this.updateCarDB();

          this.danger("Produkten har flyttats", "is-success");
        }

        this.quantity = 0;
      } else {
        this.danger(
          "Du bör ange kvantitet först , hur många stycken skulle du flytta till bilen?",
          "is-danger"
        );
      }
    },
    editStock(row, quantity) {
      this.$store.state.stockData.filter(product => {
        if (product.articleNumber == row.articleNumber) {
          product.currentQuantity = quantity;
          var ref = db.collection("Gothenburg").doc("Products");
          ref.update({
            stock: this.$store.state.stockData
          });
          this.danger("Produkten har uppdaterats", "is-success");
        }
      });
    },
    danger(msg, status) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,

        position: "is-bottom",
        type: status
      });
    },

    shippedToTheClient(row) {
      var result = row.currentQuaCar - this.quantity;
      if (this.quantity <= row.currentQuaCar) {
        this.$store.state.carData.carStock.filter(function(product) {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuaCar = result;
          }
        });
        this.updateCarDB();
        this.danger(
          "Kvantitet av den här produkten har lämnat till kunden",
          "is-success"
        );
        this.quantity = 0;
      } else {
        this.danger(
          "Du får ange kvantitet mindre eller lika med antalet i bilen ",
          "is-danger"
        );
      }
    },
    deleteProductFromCar(row) {
      this.$store.state.carData.carStock.splice(row, 1);
      this.updateCarDB();

      this.danger("Produkten har tagits bort från bilen", "is-success");
    },

    updateCarDB() {
      var ref = db
        .collection("Gothenburg")
        .doc(this.$store.state.carData.regNumber);
      ref.update({
        carStock: this.$store.state.carData.carStock
      });
    },
    changeQuantity(row) {
      this.fetch();
      var newStock = row.currentQuantity - this.quantity;
      var newCarQuantity = row.currentQuaCar + this.quantity;
      if (this.quantity <= row.currentQuantity) {
        this.$store.state.carData.carStock.filter(function(product) {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuaCar = newCarQuantity;
          }
        });
        this.$store.state.carData.carStock.filter(function(product) {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuantity = newStock;
          }
        });
        this.editStock(row, newStock);
        this.updateCarDB();
      } else {
        this.danger(
          "Du får ange kvantitet mindre eller lika med antalet i lagret ",
          "is-danger"
        );
      }
    },
    moveThistoStock(row, qua) {
      var newStockQua;

      this.$store.state.stockData.filter(function(product) {
        if (product.articleNumber == row.articleNumber) {
          newStockQua = product.currentQuantity + qua;
        }
      });
      this.editStock(row, newStockQua);
    },
    moveThisProductTo(row) {
      var qua = this.quantity;
      var newCarQuantity = row.currentQuaCar - qua;
      if (qua > 0 && qua <= row.currentQuaCar && this.selectedDis != "") {
        if (this.selectedDis == "LAGRET") {
          this.moveThistoStock(row, qua);
          if (newCarQuantity == 0) {
            this.deleteProductFromCar(row);
          } else {
            this.$store.state.carData.carStock.filter(product => {
              if (product.articleNumber == row.articleNumber) {
                product.currentQuaCar = newCarQuantity;
              }
            });
          }
        } else {
          this.moveThisProductToThisCar(row);
        }
      } else {
        this.danger(
          "Du får ange kvantitet mindre eller lika med antalet i bilen och välja vart skulle produkten flyttas ",
          "is-danger"
        );
      }
    },

    moveThisProductToThisCar(row) {
      console.log("iam live ya car");
      var des = this.selectedDis;

      var oldDesData;
      var docRef = db.collection("Gothenburg").doc(des);
      docRef.get().then(doc => {
        oldDesData = doc.data().carStock;

        this.updateAnotherCar(oldDesData, des, row);
      });
    },
    updateAnotherCar(newData, reg, row) {
      var qua = this.quantity;
      var newProduct;
      var isExist = newData.some(
        product => product.articleNumber == row.articleNumber
      );
      console.log(isExist);

      var ref = db.collection("Gothenburg").doc(reg);

      if (isExist) {
        console.log(isExist);

        newData.filter(product => {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuaCar = product.currentQuaCar + qua;
          }
        });
        ref.update({
          carStock: newData
        });
        this.$store.state.carData.carStock.filter(product => {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuaCar = product.currentQuaCar - qua;
          }
        });
        this.updateCarDB();
        this.danger("Produkten i bilen har uppdaterats ", "is-success");
      } else {
        newProduct = {
          supplier: row.supplier,
          articleNumber: row.articleNumber,
          articleName: row.articleName,
          unit: row.unit,
          currentQuantity: row.currentQuantity,
          purchasePrice: row.purchasePrice,
          totalSum: row.totalSum,
          minStock: row.minStock,
          minCar: row.minCar,
          currentQuaCar: qua
        };
        newData.push(newProduct);

        ref.update({
          carStock: newData
        });
        this.$store.state.carData.carStock.filter(product => {
          if (product.articleNumber == row.articleNumber) {
            product.currentQuaCar = product.currentQuaCar - qua;
          }
        });
        this.updateCarDB();
        this.danger("Produkten i bilen har uppdaterats ", "is-success");
      }
    }
  },
  mounted() {
    this.carsInfo();
  }
};
</script>

<style scoped>
.dangerBackground {
  background-color: tomato;
  font-weight: bolder;
}
.btn {
  margin: 5px;
}
.bil-table {
  font-weight: bolder;
  color: blueviolet;
  font-style: italic;
}
.stock-table {
  font-weight: bolder;
  color: brown;
  font-style: italic;
}
.move {
  margin: 10px;
}
.logout-tab {
  align-items: flex-end;
  align-content: flex-end;
  flex-direction: row;
}
</style>