<template>
  <div>
    <div>
      <b-field label="Artikelnummer eller Artikelbenämning" :label-position="labelPosition">
        <b-input placeholder=" SÖK ... " v-model="enteredSearch" icon-pack="fas" icon="question"></b-input>
      </b-field>
      <b-button class="btn" type="is-primary" @click="search()">SÖK...</b-button>
    </div>
    <div v-if="notFoundMsg !== ''">{{notFoundMsg}}</div>
    <div v-if="deleteThis !== false">Produkten {{productName}}</div>
    <div v-if="deleteThis !== false">
      <b-button label="TABORT" type="is-danger" size="is-medium" @click="confirmCustomDelete" />
    </div>
  </div>
</template>

<script>
import { db } from "../Firebase";

export default {
  name: "DeleteProduct",
  data() {
    return {
      searchedProduct: null,
      enteredSearch: null,
      labelPosition: "on-border",
      notFoundMsg: "",
      deleteThis: false
    };
  },
  computed: {
    productName: {
      get() {
        return this.searchedProduct.articleName;
      }
    }
  },
  methods: {
    search() {
      //console.log(this.searchedProduct, this.enteredSearch);
      this.$store.state.stockData.filter(product => {
        if (
          product.articleNumber == this.enteredSearch ||
          product.articleName == this.enteredSearch
        ) {
          this.notFoundMsg = "";
          this.deleteThis = true;
          //this.showDetails = true;

          //product.currentQuaCar = product.currentQuaCar + this.quantity;
          this.searchedProduct = product;
          //console.log(this.productName);
          //this.defaultData();
          //console.log(product);
        } else {
          this.deleteThis = false;
          this.notFoundMsg =
            "Denna produkten har inte hittades , vänligen kika namnet eller nummret";
        }
      });
    },
    confirmCustomDelete() {
      const allData = this.$store.state.stockData;

      const thisProduct = pro =>
        pro.articleNumber == this.searchedProduct.articleNumber;

      this.$buefy.dialog.confirm({
        title: "TABORT EN PRODUKT",
        message:
          "ÄR DU SÄKERT ATT <b>TABORT</b> DENNA PRODUKTEN ? TÄNK PÅ ATT DET <b>OÅNGERBART</b>",
        confirmText: "SÄKERT",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          allData.splice(allData.findIndex(thisProduct), 1);
          this.updateDB();
        }
      });
    },
    updateDB() {
      const newData = {
        stock: this.$store.state.stockData
      };

      db.collection("Gothenburg")
        .doc("Products")

        .set(newData)
        .then(() => {
          //console.log("Document successfully written!");
          this.alert("Produkten har tagits bort", "is-success");
          //this.reset();
          //this.cancelUpdate();
          this.deleteThis = false;
        })
        .catch(error => {
          console.error("Error writing document: ", error);
          this.alert("Något har gått fel ", "is-danger");
        });
    },
    alert(msg, t) {
      this.$buefy.toast.open({
        message: msg,
        type: t
      });
    }
  }
};
</script>

<style scoped>
</style>