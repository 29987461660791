<template>
  <div>
    <section>
      <b-field label="Leverantör" :label-position="labelPosition">
        <b-input
          placeholder="tex : Etac"
          v-model="supplierText"
          icon-pack="fas"
          icon="parachute-box"
        ></b-input>
      </b-field>
      <b-field label="Artikelnummer" :label-position="labelPosition">
        <b-input
          placeholder=" 343434"
          v-model.number="artNumText"
          type="number"
          icon-pack="fas"
          icon="sort-numeric-up"
        ></b-input>
      </b-field>
      <b-field label="Artikelbenämning" :label-position="labelPosition">
        <b-input
          placeholder="tex lyftmotor"
          v-model="artNameText"
          icon-pack="fas"
          icon="globe-americas"
        ></b-input>
      </b-field>
      <b-field label="Enhet" :label-position="labelPosition">
        <b-input placeholder="tex styck" v-model="uniteText" icon-pack="fas" icon="infinity"></b-input>
      </b-field>
      <b-field label="Antal i lager" :label-position="labelPosition">
        <b-input
          placeholder="tex 343434"
          v-model.number="curQuantity"
          type="number"
          icon-pack="fas"
          icon="layer-group"
        ></b-input>
      </b-field>
      <b-field label="Inköpspris" :label-position="labelPosition">
        <b-input
          placeholder="tex 343434"
          v-model.number="purPrice"
          type="number"
          icon-pack="fas"
          icon="dollar-sign"
        ></b-input>
      </b-field>
      <b-field label="Total summa" :label-position="labelPosition">
        <b-input
          placeholder="tex 343434"
          v-model.number="totSum"
          type="number"
          icon-pack="fas"
          icon="file-invoice-dollar"
        ></b-input>
      </b-field>
      <b-field label="Minst i lager" :label-position="labelPosition">
        <b-input
          placeholder="tex 343434"
          v-model.number="minStock"
          type="number"
          icon-pack="fas"
          icon="cubes"
        ></b-input>
      </b-field>
      <b-field label="Minst i bil" :label-position="labelPosition">
        <b-input
          placeholder="tex 343434"
          v-model.number="minCar"
          type="number"
          icon-pack="fas"
          icon="truck"
        ></b-input>
      </b-field>
      <div class="btn">
        <b-button type="is-success" @click="createProduct">SPARA</b-button>
      </div>

      <b-button @click="reset">ÅTERSTÄLLA</b-button>
    </section>
  </div>
</template>

<script>
import { Product } from "../classes/Product";
import { db } from "../Firebase";
export default {
  name: "AddProduct",
  components: {},
  data() {
    return {
      supplierText: "",
      artNumText: Number,
      artNameText: "",
      uniteText: "",
      curQuantity: Number,
      purPrice: Number,
      minStock: Number,
      minCar: Number,
      labelPosition: "on-border"
    };
  },
  mounted() {},
  computed: {
    totSum: {
      get() {
        return this.curQuantity * this.purPrice;
      }
    }
  },

  methods: {
    createProduct() {
      const pro = new Product("");
      (pro.supplier = this.supplierText),
        (pro.articleNumber = this.artNumText),
        (pro.articleName = this.artNameText),
        (pro.unit = this.uniteText),
        (pro.currentQuantity = this.curQuantity),
        (pro.purchasePrice = this.purPrice),
        (pro.minStock = this.minStock),
        (pro.minCar = this.minCar);
      pro.totalSum = this.totSum;
      this.addProduct(pro);
    },

    addProduct(product) {
      var p = {
        supplier: product.supplier,
        articleNumber: product.articleNumber,
        articleName: product.articleName,
        unit: product.unit,
        currentQuantity: product.currentQuantity,
        purchasePrice: product.purchasePrice,
        totalSum: product.totalSum,
        minStock: product.minStock,
        minCar: product.minCar
      };
      // newData.stock.push(p);
      //const myArr = newData.stock.slice();

      this.$store.commit("addNewProductToStock", p);
      const newData = {
        stock: this.$store.state.stockData
      };

      db.collection("Gothenburg")
        .doc("Products")

        .set(newData)
        .then(() => {
          //console.log("Document successfully written!");
          this.alert("Produkten har laddats upp", "is-success");
          this.reset();
        })
        .catch(error => {
          console.error("Error writing document: ", error);
          this.alert("Något har gått fel ", "is-danger");
        });
    },
    alert(msg, t) {
      this.$buefy.toast.open({
        message: msg,
        type: t
      });
    },
    reset() {
      (this.supplierText = ""),
        (this.artNumText = null),
        (this.artNameText = null),
        (this.uniteText = ""),
        (this.curQuantity = null),
        (this.purPrice = null),
        (this.minStock = null),
        (this.minCar = null);
    }
  }
};
</script>

<style scoped>
.btn {
  margin: 1em;
}
</style>
