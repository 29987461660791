//import Vue from "vue";

class Product {
  constructor(
    supplier,
    articleNumber,
    articleName,
    unit,
    currentQuantity,
    purchasePrice,
    totalSum,
    minStock,
    minCar
  ) {
    (this.supplier = supplier || ""),
      (this.articleNumber = articleNumber || 0),
      (this.articleName = articleName || "");
    (this.unit = unit || ""),
      (this.currentQuantity = currentQuantity || 0),
      (this.purchasePrice = purchasePrice || 0),
      (this.totalSum = totalSum || 0),
      (this.minCar = minCar || 0),
      (this.minStock = minStock || 0);
  }

  getFullInfo() {
    return this.supplier + "" + this.articleNumbr;
  }
  /*
  toFireStore(product) {
    return {
      supplier: product.supplier,
      articleNumbr: product.articleNumbr,
      articleName: product.articleName,
      unit: product.unit,
      currentQuantity: product.currentQuantity,
      purchasePrice: product.purchasePrice,
      totalSum: product.totalSum,
      minStock: product.minStock,
      minCar: product.minCar,
    };
  }
  fromFireStore(snapshot, options) {
    const data = snapshot.data(options);
    return new Product(
      data.supplier,
      data.articleNumbr,
      data.articleName,
      data.unit,
      data.currentQuantity,
      data.purchasePrice,
      data.totalSum,
      data.minStock,
      data.minCar
    );
  }
  */
}
var productConverter = {
  toFirestore: function(product) {
    //console.log("iam here");
    return {
      supplier: product.supplier,
      articleNumber: product.articleNumber,
      articleName: product.articleName,
      unit: product.unit,
      currentQuantity: product.currentQuantity,
      purchasePrice: product.purchasePrice,
      totalSum: product.totalSum,
      minStock: product.minStock,
      minCar: product.minCar,
    };
  },
  fromFirestore: function(snapshot, options) {
    const data = snapshot.data(options);
    return new Product(
      data.supplier,
      data.articleNumber,
      data.articleName,
      data.unit,
      data.currentQuantity,
      data.purchasePrice,
      data.totalSum,
      data.minStock,
      data.minCar
    );
  },
};
export { Product, productConverter };
