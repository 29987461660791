<template>
  <div class="main">Denna adress har avaktiverats , kontakta gärna Admin för mer information</div>
</template>

<script>
import { auth } from "../Firebase";
export default {
  name: "Fired",
  methods: {
    logout() {
      var user = auth.currentUser;

      user
        .delete()
        .then(function() {
          this.$store.commit("setCarData", []);
          this.$store.commit("setStockData", []);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  },
  mounted() {
    this.logout();
  }
};
</script>
<style scoped>
.main {
  align-content: center;
  align-items: center;
  font-size: 70px;
  background-color: gray;
  color: tomato;
}
</style>