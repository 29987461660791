<template>
  <div>
    <section>
      <b-tabs type="is-boxed">
        <b-tab-item label="LÄGG TILL EN NY BIL" icon="car">
          <section>
            <b-field label="Reg.nummer" :label-position="labelPosition">
              <b-input
                placeholder="tex : AAA123"
                v-model="regNumber"
                icon-pack="fas"
                icon="barcode"
              ></b-input>
            </b-field>
            <b-field label="Förarens konto" :label-position="labelPosition">
              <b-input
                placeholder="tex : barac@obama.com "
                v-model="driverEmail"
                icon-pack="fas"
                icon="address-card"
              ></b-input>
            </b-field>
            <b-field label="Märke / Modell" :label-position="labelPosition">
              <b-input
                placeholder="tex : VOLVO SC90 "
                v-model="carMark"
                icon-pack="fas"
                icon="truck-moving"
              ></b-input>
            </b-field>
            <b-field label="Senast besitkning" :label-position="labelPosition">
              <b-input
                placeholder="tex : 2019-12-28 "
                v-model="lastCheck"
                icon-pack="fas"
                type="date"
                icon="calendar-check"
              ></b-input>
            </b-field>
            <b-field label="Nästa besitkning" :label-position="labelPosition">
              <b-input
                placeholder="tex : 2022-12-28 "
                v-model="nextCheck"
                type="date"
                icon-pack="fas"
                icon="calendar-alt"
              ></b-input>
            </b-field>
          </section>

          <div class="btn">
            <b-button type="is-primary" @click="addNewCar">SPARA</b-button>
          </div>
        </b-tab-item>
        <b-tab-item label="ÄNDRA FÖRAREN TILL NÅGON BIL" icon="id-card" class="change-tab">
          <b-field label="Det nya Förarens kontot" :label-position="labelPosition">
            <b-input
              placeholder="tex : barac@obama.com "
              v-model="driverEmail"
              icon-pack="fas"
              icon="address-card"
            ></b-input>
          </b-field>
          <b-select placeholder="VÄLJA Någon BIL " v-model="selectedDis">
            <option v-for="car in carList" :value="car.reg" :key="car.id">{{car.reg}}</option>
          </b-select>
          <b-button type="is-warning" @click="changeDriver()">ÄNDRA</b-button>
        </b-tab-item>
        <b-tab-item label="TABORT EN BIL" icon="car-crash">
          <b-field label="Reg.nummer" :label-position="labelPosition">
            <b-input placeholder="tex : AAA123" v-model="regNumber" icon-pack="fas" icon="barcode"></b-input>
          </b-field>

          <b-button label="TABORT" type="is-danger" size="is-medium" @click="confirmCustomDelete" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { db } from "../Firebase";

export default {
  name: "CarMangement",
  data() {
    return {
      labelPosition: "on-border",
      regNumber: "",
      driverEmail: "",
      carMark: "",
      lastCheck: Date,
      nextCheck: Date,
      selectedDis: String
    };
  },
  computed: {
    carList() {
      return this.$store.state.carsList;
    }
  },
  methods: {
    addNewCar() {
      var car = {
        regNumber: this.regNumber,
        driverEmail: this.driverEmail,
        carMark: this.carMark,
        lastCheck: this.lastCheck,
        nextCheck: this.nextCheck,
        carStock: []
      };
      db.collection("Gothenburg")
        .doc(car.regNumber)

        .set(car)
        .then(() => {
          //console.log("Document successfully written!");
          this.alert("Bilen har laddats upp", "is-success");
          this.reset();
        })
        .catch(error => {
          console.error("Error writing document: ", error);
          this.alert("Något har gått fel ", "is-danger");
        });
    },

    alert(msg, t) {
      this.$buefy.toast.open({
        message: msg,
        type: t
      });
    },
    reset() {
      (this.regNumber = ""),
        (this.driverEmail = ""),
        (this.carMark = ""),
        (this.lastCheck = null),
        (this.nextCheck = null),
        (this.selectedDis = "");
    },
    carsInfo() {
      var id = 0;
      //var def = { id: 0, reg: "LAGRET" };
      var tempArr = [];
      var newCar = {};
      this.$store.commit("setCarsList", tempArr);

      var docRef = db.collection("Gothenburg");

      docRef
        .where("driverEmail", "!=", "")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var reg = doc.id;

            id++;
            newCar = { id, reg };

            this.$store.commit("addNewCarToList", newCar);
            console.log("this is the list", newCar.reg);
          });
        })
        .catch(error => {
          console.log(error);
          this.danger("Något har gått fel", "is-danger");
        });
    },
    changeDriver() {
      var email = this.driverEmail;
      var car = this.selectedDis;
      var docRef = db.collection("Gothenburg").doc(car);
      docRef
        .update({
          driverEmail: email
        })
        .then(() => {
          this.reset();
          this.danger("BILEN HAR UPPDATERATS", "is-success");
        });
    },
    danger(msg, status) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,

        position: "is-bottom",
        type: status
      });
    },
    confirmCustomDelete() {
      this.$buefy.dialog.confirm({
        title: "TABORT BILEN",
        message:
          "ÄR DU SÄKERT ATT <b>TABORT</b> DENNA BIL ? TÄNK PÅ ATT DET SKULLE RENSA ALLA INFORMATION OM DEN HÄR BILEN FRÅN HELA SYSTEMET OCH DET OÅNGERBART",
        confirmText: "SÄKERT",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          db.collection("Gothenburg")
            .doc(this.regNumber)
            .delete()
            .then(() => {
              this.danger("BILEN HAR TAGITS BORT", "is-success");
              this.regNumber = "";
            })
            .catch(error => {
              console.error("Error removing document: ", error);
            });
        }
      });
    }
  },
  mounted() {
    this.carsInfo();
  }
};
</script>

<style scoped>
.btn {
  margin: 1em;
}
</style>