<template>
  <div>
    <div>
      <b-field label="Artikelnummer eller Artikelbenämning" :label-position="labelPosition">
        <b-input placeholder=" SÖK ... " v-model="enteredSearch" icon-pack="fas" icon="question"></b-input>
      </b-field>
      <b-button class="btn" type="is-primary" @click="search()">SÖK...</b-button>
    </div>
    <div v-if="notFoundMsg !== ''">{{notFoundMsg}}</div>
    <div v-if="showDetails === true">
      <section>
        <b-field label="Leverantör" :label-position="labelPosition">
          <b-input
            placeholder="tex : Etac"
            v-model="supplierText"
            icon-pack="fas"
            icon="parachute-box"
          ></b-input>
        </b-field>
        <b-field label="Artikelnummer" :label-position="labelPosition">
          <b-input
            placeholder=" 343434"
            v-model.number="artNumText"
            type="number"
            icon-pack="fas"
            icon="sort-numeric-up"
          ></b-input>
        </b-field>
        <b-field label="Artikelbenämning" :label-position="labelPosition">
          <b-input
            placeholder="tex lyftmotor"
            v-model="artNameText"
            icon-pack="fas"
            icon="globe-americas"
          ></b-input>
        </b-field>
        <b-field label="Enhet" :label-position="labelPosition">
          <b-input placeholder="tex styck" v-model="uniteText" icon-pack="fas" icon="infinity"></b-input>
        </b-field>
        <b-field label="Antal i lager" :label-position="labelPosition">
          <b-input
            placeholder="tex 343434"
            v-model.number="curQuantity"
            type="number"
            icon-pack="fas"
            icon="layer-group"
          ></b-input>
        </b-field>
        <b-field label="Inköpspris" :label-position="labelPosition">
          <b-input
            placeholder="tex 343434"
            v-model.number="purPrice"
            type="number"
            icon-pack="fas"
            icon="dollar-sign"
          ></b-input>
        </b-field>
        <b-field label="Total summa" :label-position="labelPosition">
          <b-input
            placeholder="tex 343434"
            v-model.number="totSum"
            type="number"
            icon-pack="fas"
            icon="file-invoice-dollar"
          ></b-input>
        </b-field>
        <b-field label="Minst i lager" :label-position="labelPosition">
          <b-input
            placeholder="tex 343434"
            v-model.number="minStock"
            type="number"
            icon-pack="fas"
            icon="cubes"
          ></b-input>
        </b-field>
        <b-field label="Minst i bil" :label-position="labelPosition">
          <b-input
            placeholder="tex 343434"
            v-model.number="minCar"
            type="number"
            icon-pack="fas"
            icon="truck"
          ></b-input>
        </b-field>
        <div class="btn">
          <b-button type="is-success" @click="saveEditedProduct">UPPDATERA</b-button>
        </div>

        <b-button @click="cancelUpdate">AVBRYT</b-button>
      </section>
    </div>
  </div>
</template>

<script>
//import { Product } from "../classes/Product";
import { db } from "../Firebase";
export default {
  name: "EditProduct",
  components: {},
  data() {
    return {
      searchedProduct: null,
      enteredSearch: null,

      newSupplier: "",
      newArtNum: Number,
      newArtName: "",
      newUnite: "",
      newCurQuantity: Number,
      newPurPrice: Number,
      newMinStock: Number,
      newMinCar: Number,
      showDetails: false,
      notFoundMsg: "",
      labelPosition: "on-border"
    };
  },
  mounted() {},
  computed: {
    totSum: {
      get() {
        return this.curQuantity * this.purPrice;
      }
    },
    newTotSum: {
      get() {
        return this.newCurQuantity * this.newPurPrice;
      }
    },
    supplierText: {
      get() {
        return this.searchedProduct.supplier;
      },

      set(newValue) {
        console.log(newValue);
        this.newSupplier = newValue;
      }
    },
    artNumText: {
      get() {
        return this.searchedProduct.articleNumber;
      },
      set(newValue) {
        this.newArtNum = newValue;
      }
    },
    artNameText: {
      get() {
        return this.searchedProduct.articleName;
      },
      set(newValue) {
        this.newArtName = newValue;
      }
    },
    uniteText: {
      get() {
        return this.searchedProduct.unit;
      },
      set(newValue) {
        this.newUnite = newValue;
      }
    },
    curQuantity: {
      get() {
        return this.searchedProduct.currentQuantity;
      },
      set(newValue) {
        this.newCurQuantity = newValue;
      }
    },
    purPrice: {
      get() {
        return this.searchedProduct.purchasePrice;
      },
      set(newValue) {
        this.newPurPrice = newValue;
      }
    },
    minStock: {
      get() {
        return this.searchedProduct.minStock;
      },
      set(newValue) {
        this.newMinStock = newValue;
      }
    },
    minCar: {
      get() {
        return this.searchedProduct.minCar;
      },
      set(newValue) {
        this.newMinCar = newValue;
      }
    }
  },

  methods: {
    /*
    createProduct() {
      //console.log(this.supplierText, this.newSupplier);
      //var testttt = this.searchedProduct.supplier;
      //console.log(testttt);
      console.log(this.searchedProduct);
      console.log(this.newSupplier, this.newArtNum, this.newMinStock);
      
      const pro = new Product("");
      (pro.supplier = this.supplierText),
        (pro.articleNumber = this.artNumText),
        (pro.articleName = this.artNameText),
        (pro.unit = this.uniteText),
        (pro.currentQuantity = this.curQuantity),
        (pro.purchasePrice = this.purPrice),
        (pro.minStock = this.minStock),
        (pro.minCar = this.minCar);
      pro.totalSum = this.totSum;
      this.addProduct(pro);
      
    },
    */
    search() {
      //console.log(this.searchedProduct, this.enteredSearch);
      this.$store.state.stockData.filter(product => {
        if (
          product.articleNumber == this.enteredSearch ||
          product.articleName == this.enteredSearch
        ) {
          this.notFoundMsg = "";
          this.showDetails = true;

          //product.currentQuaCar = product.currentQuaCar + this.quantity;
          this.searchedProduct = product;
          this.defaultData();
          //console.log(product);
        } else {
          this.showDetails = false;
          this.notFoundMsg =
            "Denna produkten har inte hittades , vänligen kika namnet eller nummret";
        }
      });
    },
    //deleteThisProduct() {},
    saveEditedProduct() {
      const allData = this.$store.state.stockData;

      const thisProduct = pro =>
        pro.articleNumber == this.searchedProduct.articleNumber;

      var p = {
        supplier: this.newSupplier,
        articleNumber: this.newArtNum,
        articleName: this.newArtName,
        unit: this.newUnite,
        currentQuantity: this.newCurQuantity,
        purchasePrice: this.newPurPrice,
        totalSum: this.newTotSum,
        minStock: this.newMinStock,
        minCar: this.newMinCar
      };

      allData.splice(allData.findIndex(thisProduct), 1, p);
      this.updateDB();
    },

    defaultData() {
      this.newArtName = this.artNameText;
      this.newArtNum = this.artNumText;
      this.newSupplier = this.supplierText;
      this.newUnite = this.uniteText;
      this.newCurQuantity = this.curQuantity;
      this.newPurPrice = this.purPrice;
      this.newMinStock = this.minStock;
      this.newMinCar = this.minCar;
    },

    updateDB() {
      const newData = {
        stock: this.$store.state.stockData
      };

      db.collection("Gothenburg")
        .doc("Products")

        .set(newData)
        .then(() => {
          //console.log("Document successfully written!");
          this.alert("Produkten har uppdaterats", "is-success");
          //this.reset();
          this.cancelUpdate();
        })
        .catch(error => {
          console.error("Error writing document: ", error);
          this.alert("Något har gått fel ", "is-danger");
        });
    },
    cancelUpdate() {
      this.showDetails = false;
      this.searchedProduct = null;
    },
    alert(msg, t) {
      this.$buefy.toast.open({
        message: msg,
        type: t
      });
    }
    /*
    reset() {
      (this.supplierText = ""),
        (this.artNumText = null),
        (this.artNameText = null),
        (this.uniteText = ""),
        (this.curQuantity = null),
        (this.purPrice = null),
        (this.minStock = null),
        (this.minCar = null);
    }
    */
  }
};
</script>

<style scoped>
.btn {
  margin: 1em;
}
</style>
