import Vue from "vue";
import firebase from "firebase/app";
import Firebase from "firebase";
import "@firebase/firestore";
import "@firebase/auth";

//import Product from "./classes/Product";

//const firebase = require("firebase");
// Required for side-effects
//require("firebase/firestore");
var firebaseConfig = {
  apiKey: "AIzaSyCzoONL_j5ENykSxKKmElwDi-OAI6NQt6I",
  authDomain: "roux-9e4d3.firebaseapp.com",
  projectId: "roux-9e4d3",
  storageBucket: "roux-9e4d3.appspot.com",
  messagingSenderId: "242783796855",
  appId: "1:242783796855:web:6e45e02ba790bf17c91072",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.use(Firebase);

const db = Firebase.firestore();
const auth = Firebase.auth();

export { db, auth };
