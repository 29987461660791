<template>
  <div>
    <section>
      <b-tabs type="is-boxed">
        <b-tab-item label="LÄGG TILL EN NY PRODUKT" icon="receipt">
          <AddProduct />
        </b-tab-item>
        <b-tab-item label="ÄNDRA PÅ EN PRODUKT" icon="pallet">
          <EditProduct />
        </b-tab-item>
        <b-tab-item label="TABORT EN PRODUKT" icon="trash-alt">
          <DeleteProduct />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";
export default {
  name: "ProductsMangement",
  components: {
    AddProduct,
    EditProduct,
    DeleteProduct
  }
};
</script>

<style scoped>
</style>