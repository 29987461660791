<template>
  <div>
    <b-field label="Email" :label-position="labelPosition">
      <b-input
        placeholder="tex : someone@somewhere.se"
        v-model="enteredEmail"
        icon-pack="fas"
        type="email"
        required
        icon="at"
      ></b-input>
    </b-field>
    <b-field label="Lösenord" :label-position="labelPosition">
      <b-input
        placeholder="tex : topsecretpassword"
        v-model="enteredPassword"
        type="password"
        password-reveal
        required
        icon-pack="fas"
        icon="key"
      ></b-input>
    </b-field>
    <b-button type="is-success" @click="tryLogin()">LOGGA IN</b-button>
    <div class="forget">
      <a @click="prompt()">Glömde / Ändra lösenordet</a>
    </div>
  </div>
</template>

<script>
import { db, auth } from "../Firebase";
import { ToastProgrammatic as Toast } from "buefy";
export default {
  name: "Login",
  data() {
    return {
      enteredPassword: "",
      enteredEmail: "",
      labelPosition: "on-border"
    };
  },
  methods: {
    tryLogin() {
      auth
        .signInWithEmailAndPassword(this.enteredEmail, this.enteredPassword)
        .then(user => {
          this.$store.commit("setCurrentUser", user.user);
          this.$store.commit("setUserEmail", user.user.email);
          this.$store.commit("setUserRole", user.user.displayName);
          this.fetchCarData();
          //this.carsInfo();
          this.fetchUnWanted();
          this.danger("Välkommen tillbaka", "is-success");
          console.log(this.$store.state.userRole);
        })
        .catch(err => {
          this.danger(err.message, "is-danger");
        });

      this.fetchStockData();
    },
    fetchCarData() {
      //console.log("some one called me");
      var docRef = db.collection("Gothenburg");
      if (this.$store.state.userRole == "tech") {
        docRef
          .where("driverEmail", "==", this.$store.state.userEmail)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              this.$store.commit("setCarData", doc.data());
              //console.log(this.$store.state.carData.carStock);
            });
          })
          .catch(error => {
            console.log(error);
            this.danger("Något har gått fel", "is-danger");
          });
      }
    },
    fetchStockData() {
      this.$store.commit("setStockData", []);
      db.collection("Gothenburg")
        .doc("Products")
        .get()
        .then(doc => {
          doc.data().stock.forEach(element => {
            this.$store.commit("addNewProductToStock", element);
            //console.log("this is the element", element);
          });
        });
    },
    fetchUnWanted() {
      var unWantedList = db.collection("Gothenburg").doc("unWantedUsers");
      unWantedList.get().then(doc => {
        this.$store.commit("setUnWantedUsers", doc.data().users);
        console.log("unwanted data is : ", doc.data().users);
        var check = this.$store.state.unWantedUsers.includes(this.enteredEmail);
        if (check) {
          console.log("check is : ", check);
          this.$store.commit("setUserRole", "fired");
          console.log(this.$store.state.userRole);
        } else {
          console.log("check is false ", check);
        }
      });
    },
    danger(msg, status) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,

        position: "is-bottom",
        type: status
      });
    },
    prompt() {
      this.$buefy.dialog.prompt({
        message: `Ange din email adress`,
        inputAttrs: {
          placeholder: "tex : someone@somewhere.se"
          //maxlength: 10
        },
        trapFocus: true,
        onConfirm: value => this.changePass(value)
      });
    },
    changePass(email) {
      auth
        .sendPasswordResetEmail(email)
        .then(function() {
          /*
          this.danger(
            "en email har skickades till din mejl adress , följa instruktioner som kommit ",
            "is-danger"
          );
          */
          Toast.open(
            "en email har skickades till din mejl adress , följa instruktioner som kommit "
          );
        })
        .catch(function(error) {
          console.log(error);
          //this.danger("Något har gått fel", "is-danger");
          Toast.open(
            "Något har gått fel , kika gärna att du har skrivit en rätt mejl adress "
          );
        });
    }
  },
  mounted() {}
};
</script>

<style scoped>
.forget {
  margin: 20px;
}
</style>