<template>
  <div>
    <b-table
      :data="stockData"
      ref="table"
      paginated
      bordered
      striped
      hoverable
      per-page="10"
      detailed
      scrollable
      detail-key="articleNumber"
      show-detail-icon
    >
      <b-table-column
        centered
        field="articleNumber"
        label=" Artikelnummer"
        numeric
        v-slot="stockData"
      >{{stockData.row.articleNumber}}</b-table-column>

      <b-table-column
        centered
        field="articleName"
        label="Artikelbenämning"
        v-slot="stockData"
        searchable
      >{{stockData.row.articleName}}</b-table-column>
      <b-table-column
        centered
        field="currentQuantity"
        label="Antal i lagret"
        numeric
        sortable
        v-slot="stockData"
      >
        <div v-if="stockData.row.currentQuantity <= stockData.row.minStock">
          <div class="dangerBackground">{{stockData.row.currentQuantity}}</div>
        </div>
        <div v-else>{{stockData.row.currentQuantity}}</div>
      </b-table-column>
      <b-table-column
        centered
        field="minCar"
        label="Minst i bilen"
        numeric
        v-slot="stockData"
      >{{stockData.row.minCar}}</b-table-column>
      <b-table-column
        centered
        field="minCar"
        label="Minst i lagret"
        numeric
        v-slot="stockData"
      >{{stockData.row.minStock}}</b-table-column>

      <!--

      -->

      <!--
        <b-table-column
          field="minStock"
          label="Minst i lagret"
          numeric
          v-slot="stockData"
        >{{stockData.row.minStock}}</b-table-column>
      -->
      <template #detail="stockData">
        <article class="media">
          <figure class="media-left">
            <!--<b-field label="Ange kvantitet" label-position="on-border">
              <b-input
                placeholder="tex : 12"
                v-model.number="quantity"
                type="number"
                icon-pack="fas"
                icon="dolly"
              ></b-input>
              <br />
            </b-field>

            <b-button
              class="btn"
              type="is-primary"
              @click="moveProductToMyCar(stockData.row)"
            >LÄGG PRODUKTEN TILL BILEN</b-button>


            show-detail-icon-->
            <!-- here -->
            <!--
            <b-table
              :data="stockData"
              ref="table"
              paginated
              bordered
              striped
              hoverable
              scrollable
              per-page="10"
              detailed
              detail-key="articleNumber"
            >
              <b-table-column
                centered
                field="articleNumber"
                label=" Artikelnummer"
                numeric
                v-slot="stockData"
              >{{stockData.row.articleNumber}}</b-table-column>
            </b-table>
            
            <b-collapse :open="false" aria-id="contentIdForA11y1">
              <template #trigger>
                <b-button label="Click me!" type="is-primary" aria-controls="contentIdForA11y1" />
              </template>
              <div class="notification">
                <div
                  class="content"
                  v-for="car in carsList"
                  :key="car.data.articleNumber"
                >{{car.reg}} :</div>
              </div>
            </b-collapse>
            -->

            <div>
              <b-button
                label="Visa bilar"
                type="is-primary"
                size="is-medium"
                @click="searchThisProduct(stockData.row)"
              />
            </div>
            <div v-if="showCars == true">
              <div v-if="thisProduct == stockData.row.articleName">
                <div v-for="car in resultCars" :key="car.car">{{car.car}} : {{car.qua}}</div>
              </div>
            </div>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Enhet : {{ stockData.row.unit }}</strong>
                <br />

                <strong>Inköpspris : {{ stockData.row.purchasePrice }}</strong>
                <br />
                <strong>Leverantör : {{ stockData.row.supplier }}</strong>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
import { db } from "../Firebase";
export default {
  name: "StockMangement",
  data() {
    return {
      defaultOpenedDetails: [],
      isBordered: true,
      carsList: [],
      showCars: false,
      resultCars: [],
      thisProduct: String
      // carsData : {}
    };
  },
  computed: {
    stockData() {
      return this.$store.state.stockData;
    }
    /*
    carList() {
      return this.$store.state.carsList;
    }
    */
  },
  methods: {
    searchThisProduct(row) {
      var foundedCar = {};
      this.resultCars = [];
      this.showCars = !this.showCars;
      this.thisProduct = row.articleName;

      this.carsList.forEach(element => {
        var isExist = element.data.some(
          product => product.articleNumber == row.articleNumber
        );
        if (isExist) {
          element.data.filter(pro => {
            if (pro.articleNumber == row.articleNumber) {
              //console.log(element.reg, "this car has ", pro.currentQuaCar);
              const car = element.reg;
              const qua = pro.currentQuaCar;
              foundedCar = { car, qua };
              this.resultCars.push(foundedCar);
            }
          });
        } else {
          const car = element.reg;
          const qua = 0;
          foundedCar = { car, qua };
          this.resultCars.push(foundedCar);
        }
      });
    },

    carsInfo() {
      //var id = 0;
      //var def = { id: 0, reg: "LAGRET" };
      //var tempArr = [];
      var newCar = {};
      var data = {};
      //this.$store.commit("setCarsList", tempArr);

      var docRef = db.collection("Gothenburg");

      docRef
        .where("driverEmail", "!=", "")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var reg = doc.id;
            data = doc.data().carStock;
            //id++;
            newCar = { reg, data };
            //console.log(newCar);
            this.carsList.push(newCar);
            console.log(this.carsList);
            //this.$store.commit("addNewCarToList", newCar);
            //console.log("this is the list", newCar.reg);
          });
        })
        .catch(error => {
          console.log(error);
          this.danger("Något har gått fel", "is-danger");
        });
    }
  },

  mounted() {
    this.carsInfo();
  }
};
</script>

<style scoped>
.dangerBackground {
  background-color: tomato;
  font-weight: bolder;
}
</style>